import React from "react"
import { Link } from "gatsby"
import { Spring, animated } from 'react-spring/renderprops'

class Header extends React.Component {
  state = {
    menuExpanded: false,
    menuToggleClicked: false,
    alpha: 0,
  }
  toggleMenuExpansion = () => {
    this.setState({
      menuToggleClicked: true,
      menuExpanded: !this.state.menuExpanded
    })
  }
  render() {
    const menuExpanded = this.state.menuExpanded
    const menuClassName = menuExpanded ? `expanded` : ``
    return (
      <header>
        <section className="primary">
          <Link to="/" title="Home">Kuokoa Studio</Link>
          <button title="Toggle Navigation Menu" onClick={() => {this.toggleMenuExpansion()}}>
            <svg width="42px" height="42px" viewBox="0 0 42 42">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="menu-circle" fill="#000000" cx="21" cy="21" r="20"></circle>
                <Spring
                  reset
                  immediate={!this.state.menuToggleClicked}
                  from={{
                    opacity: menuExpanded ? 0 : 1,
                    transform: 'rotate(' + (menuExpanded ? 0 : 180) + 'deg)',
                    transformOrigin: '50% 50%',
                  }}
                  to={{
                    opacity: menuExpanded ? 1 : 0,
                    transform: 'rotate(' + (menuExpanded ? 180 : 0) + 'deg)',
                    transformOrigin: '50% 50%',
                  }}
                  config={{ duration: menuExpanded ? 500 : 250 }}>
                  { props => {
                    return <animated.polygon style={props} id="menu-close" fill="#FFFFFF" points="28.9705627 12 30.0312229 13.0606602 22.076 21.015 30.0312229 28.9705627 28.9705627 30.0312229 21.015 22.076 13.0606602 30.0312229 12 28.9705627 19.955 21.015 12 13.0606602 13.0606602 12 21.015 19.955" />
                  }}
                </Spring>
                <Spring
                  reset
                  immediate={false}
                  from={{
                    opacity: menuExpanded ? 1 : 0,
                    transform: 'rotate(' + (menuExpanded ? 0 : 180) + 'deg)',
                    transformOrigin: '50% 50%',
                  }}
                  to={{
                    opacity: menuExpanded ? 0 : 1,
                    transform: 'rotate(' + (menuExpanded ? 180 : 0) + 'deg)',
                    transformOrigin: '50% 50%',
                  }}
                  config={{
                    tension: 120,
                    friction: 10
                  }}>
                  { props => {
                    return <animated.path style={props} id="menu-needle" d="M19.5857864,19.5857864 L15.3431458,26.6568542 L22.4142136,22.4142136 L19.5857864,19.5857864 Z M12.5147186,29.4852814 L18.8786797,18.8786797 L29.4852814,12.5147186 L23.1213203,23.1213203 L12.5147186,29.4852814 Z" fill="#FFFFFF" />
                  }}
                </Spring>
              </g>
            </svg>
          </button>
        </section>
        <Spring
          immediate={!this.state.menuToggleClicked}
          from={{
            top: !menuExpanded ? '0rem' : '-24rem'
          }}
          to={{
            top: !menuExpanded ? '-24rem' : '0rem'
          }}
          config={{
            tension: 180,
            friction: 30
          }}>
          { props => {
            return <section className="secondary">
              <menu style={props} className={menuClassName}>
                <Link to="/works">Works</Link>
                <Link to="/ethos">Ethos</Link>
                <Link to="/collaborate">Collaborate</Link>
                {(false && <Link to="/journal">Journal</Link>)}
                <a href="https://kuokoa.life">Trade</a>
                <a href="mailto:aloha@kuokoa.studio">Aloha</a>
              </menu>
            </section>
          }}
        </Spring>
      </header>
    )
  }
}

export default Header
