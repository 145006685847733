import React from "react"

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <section>
          <h3>Kuokoa Studio</h3>
          <p>111 Hekili Street, Suite A498<br />
          Kailua, HI 96734</p>
          <p><a href="tel://+18083437953">(808) 343-7953</a></p>
        </section>
        <section>
          <img src="/flag.svg" alt="Hawaiian State Flag" title="Ua Mau ke Ea o ka ʻĀina i ka Pono" />
          <p>Made in Hawaiʻi</p>
          <p>&copy; 2017-{new Date().getFullYear()} Kuokoa, LLC</p>
        </section>
      </footer>
    )
  }
}

export default Footer
